var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "m-t-10",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { span: "24" } },
            [
              _c(
                "el-col",
                { staticClass: "p-r-10", attrs: { span: "12" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "站点名称", prop: "siteName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          readonly: _vm.isDetail,
                          clearable: "",
                          placeholder: "请输入站点名称",
                        },
                        model: {
                          value: _vm.form.siteName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "siteName", $$v)
                          },
                          expression: "form.siteName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "p-r-10", attrs: { span: "6" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "维护人员", prop: "mobileName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          readonly: _vm.isDetail,
                          clearable: "",
                          placeholder: "请输入维护人员",
                        },
                        model: {
                          value: _vm.form.mobileName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobileName", $$v)
                          },
                          expression: "form.mobileName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "p-r-10", attrs: { span: "6" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          readonly: _vm.isDetail,
                          maxlength: "11",
                          clearable: "",
                          placeholder: "请输入 11 位的手机号",
                        },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "15px" }, attrs: { span: "24" } },
            [
              _c(
                "el-col",
                { attrs: { span: "24" } },
                [
                  _c("biz-edit", {
                    attrs: {
                      "data-obj": _vm.form,
                      "is-admin": _vm.isAdmin,
                      "is-detail": _vm.isDetail,
                      "is-add": _vm.isAdd,
                      "show-site": false,
                    },
                    on: { "select-company": _vm.onSelectCompany },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              style: _vm.isAdmin ? `margin-top:75px;` : `margin-top:15px;`,
              attrs: { span: "24" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remrks" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          readonly: _vm.isDetail,
                          type: "textarea",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.remrks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remrks", $$v)
                          },
                          expression: "form.remrks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { style: `height:${_vm.mapHeight}px;`, attrs: { span: "24" } },
            [
              _c(
                "el-col",
                { attrs: { span: "24" } },
                [
                  _c("region-core", {
                    ref: "region",
                    attrs: {
                      "site-info": _vm.form,
                      height: _vm.mapHeight,
                      "is-add": _vm.isAdd,
                      "is-detail": _vm.isDetail,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "div",
            [
              _c("foot-btns", {
                on: { cancel: _vm.onCancel, submit: _vm.onSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }