<template>
  <div v-loading="loading" class="m-t-10">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="left">
      <el-row span="24">
        <el-col span="12" class="p-r-10">
          <el-form-item label="站点名称" prop="siteName">
            <el-input size="mini" :readonly="isDetail" clearable v-model="form.siteName" placeholder="请输入站点名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="6" class="p-r-10">
          <el-form-item label="维护人员" prop="mobileName">
            <el-input size="mini" :readonly="isDetail" clearable v-model="form.mobileName" placeholder="请输入维护人员"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="6" class="p-r-10">
          <el-form-item label="联系方式" prop="mobile">
            <el-input size="mini" :readonly="isDetail" maxlength="11" clearable v-model="form.mobile" placeholder="请输入 11 位的手机号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row span="24" style="margin-top:15px;">
        <el-col span="24">
          <biz-edit :data-obj="form" :is-admin="isAdmin" :is-detail="isDetail" :is-add="isAdd" :show-site="false"
            @select-company="onSelectCompany" />
        </el-col>
      </el-row>
      <el-row span="24" :style="isAdmin ? `margin-top:75px;` : `margin-top:15px;`">
        <el-col span="24">
          <el-form-item label="备注" prop="remrks">
            <el-input size="mini" :readonly="isDetail" type="textarea" clearable v-model="form.remrks"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row span="24" :style="`height:${mapHeight}px;`">
        <el-col span="24">
          <region-core ref="region" :site-info="form" :height="mapHeight" :is-add="isAdd" :is-detail="isDetail" />
        </el-col>
      </el-row>
    </el-form>
    <div v-if="!isDetail">
        <foot-btns @cancel="onCancel" @submit="onSubmit" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import siteApi from "@/api/site";

import BizEdit from "@/components/business/edit";
import RegionCore from "./../region/core";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    BizEdit,
    RegionCore
  },
  props: {
    company: {
      type: Boolean,
      default: {}
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    bizId: {
      type: String,
      default: ""
    },
    data: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      mapHeight: 0,
      isAdmin: false,
      volumetric: "",
      form: {
        city: "",
        tenantId: "",
        businessId: "",
        companyId: "",
        companyName: "",
        location: window.LngLat,
        longitude: "",
        latitude: "",
        number: 0,
        siteId: "",
        siteName: "",
        mobileName: "",
        mobile: "",
        regionName: "",
        type: 2
      },
      rules: {
        siteName: [
          { required: true, trigger: "blur", message: "请输入站点名称" }
        ],
        mobileName: [
          { required: true, trigger: "blur", message: "请输入维护人员" }
        ],
        mobile: [
          { required: true, trigger: "blur", message: "请输入 11 位的手机号" },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ],
        regionName: [
          { required: true, trigger: "change", message: "请输入所在城市+详细地址，如：北京市天安门" }
        ]
      },
    }
  },
  watch: {
    data: function() {
      this.getDatas();
    },
    isDetail: function () {
      this.calcHeight();
      this.getDatas();
    }
  },
  created () {
    console.log("site-detail-edit");
    this.isAdmin = this.user.tenantId === 0;
    this.calcHeight();
    if (!this.isDetail) {
      this.getDatas();
    }
  },
  methods: {
    getDatas () {
      let data = Object.assign({}, this.form);
        if (!this.isAdmin && this.isAdd) {
          data.tenantId = this.user.tenantId;
          data.businessId = data.companyId = this.user.businessId;
        }
      if (this.data) {
        data = Object.assign(data, this.data);
      }

      this.form = data;
      this.backForm = Object.assign({}, data);
      this.$forceUpdate();
    },
    onClose () {
      if (this.isAdd) {
        this.$router.push({
          path: `/site`
        });
      } else {
        this.$emit("close", this.submitData);
      }
    },
    onCancel () {
      const title = this.isAdd ? `添加站点` : `编辑当前站点（${this.form.siteName}）`;

      $utils.cancelConfirm(this, title, () => {
        this.submitData = this.backForm;
        this.onClose();
      });
    },
    onSubmit () {
      this.loading = true;
      this.$refs.form.validate(async valid => {

        const method = this.isAdd ? "add" : "edit";
        const type = this.isAdd ? "添加" : "编辑";

        try {
          if (valid) {
            const submitData = Object.assign({}, this.form);

            delete submitData.location;

            if (this.isAdd) {
              submitData.userId = this.user.userId;
              submitData.creator = this.user.username;
            }
            submitData.companyName = this.company.companyName + this.volumetric;
            submitData.companyId = this.company.id;
            submitData.staffCode = this.company.subsidiaryId;

            const res = await siteApi[method](submitData);

            this.submitData = submitData;
            
            this.$message({
              message: `站点（${submitData.siteName}）${type}成功`,
              type: "success"
            });

            let sites = sessionStorage.getItem("sites");
            if (sites === null) {
              sites = {};
            } else {
              sites = JSON.parse(sites);
            }

            if (this.isAdd) {
              // this.form.id = res;
              // const list = await siteApi.list({
              //   siteName: submitData.siteName
              // });
              
              // const site = list.data[0];

              setTimeout(() => {
                this.$router.push({
                  path: `/site/`
                });
              }, 500);
            } else {
              this.onClose();
            }
          }
        } catch (e) {
          this.$message({
              message: `站点（${this.form.siteName}）${type}失败`,
              type: "error"
            });
        } finally {
          this.loading = false;
        }
      });
    },
    calcHeight () {
      if (this.isAdd) {
        this.mapHeight = document.body.scrollHeight - 404;
      } else {
        if (this.isDetail) {
          this.mapHeight = document.body.scrollHeight - 364;
        } else {
          this.mapHeight = document.body.scrollHeight - 418;
        }
      }

      if (!this.isAdmin) {
        this.mapHeight += 60;
      }
    },
    onSelectCompany (company) {
      const { configs } = this.company = company;
      if ($statics.BUSINESS_CUSTOM_CONFIG_REGX.test(configs)) {
        let customConfig = configs.match($statics.BUSINESS_CUSTOM_CONFIG_REGX);
        customConfig = JSON.parse(customConfig[1]);
        this.volumetric = customConfig.volumetric || "";
      }
    },
    saveData (sites, submitData) {
      sites[submitData.siteId] = submitData;
      sessionStorage.setItem("sites", JSON.stringify(sites));
    }
  }
}
</script>